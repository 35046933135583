<template>
  <div>
    <b-modal
      id="conductCriteriaModal"
      title="Danh sách tiêu chí"
      size="xl"
      body-class="position-static"
      centered
      @shown="onShow"
      :no-close-on-backdrop="true"
    >
      <div class="m-1">
        <b-row v-if="creatable">
          <b-col cols="12">
            <div class="text-right">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.conductCriteriaSaveModal
                variant="success"
                @click="currentConductCriteria = undefined"
              >
                <span class="text-nowrap text-right">
                  <feather-icon icon="PlusIcon" /> Thêm tiêu chí
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <vue-good-table
              mode="remote"
              :columns="columns"
              :rows="dataSources"
              :pagination-options="paginationOptions"
              :total-rows="totalRows"
              :line-numbers="true"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'status'">
                  {{ getStatusName(props.row.status) }}
                </span>

                <span v-else-if="props.column.field === 'action'">
                  <b-button
                    v-if="updatable"
                    v-b-modal.conductCriteriaSaveModal
                    variant="primary"
                    class="btn-icon"
                    size="sm"
                    @click="onEditConductCriteria(props.row)"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>
                  <b-button
                    v-if="deletable"
                    variant="danger"
                    class="btn-icon ml-1"
                    size="sm"
                    @click="onDeleteConductCriteria(props.row)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>{{ props.formattedRow[props.column.field] }}</span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị 1 đến </span>
                    <b-form-select
                      v-model="filter.itemsPerPage"
                      :options="itemsPerPageOptions"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">của {{ dataSources.length }} bản ghi</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="totalRows"
                      :per-page="filter.itemsPerPage"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    />
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('conductCriteriaModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>

    <ConductCriteriaSave
      :conduct-id="conductId"
      :conduct-criteria="currentConductCriteria"
      @succeed="onSucceed"
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCol,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BModal, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { STATUSES } from '@/const/status'
import { getUser } from '@/auth/utils'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import ConductCriteriaSave from '@/views/admin/category/conduct/conduct-criteria/ConductCriteriaSave.vue'

export default {
  name: 'ConductCriteria',
  directives: {
    Ripple,
  },
  components: {
    ConductCriteriaSave,
    BCol,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BModal,
    BButton,
    VueGoodTable,
  },
  props: {
    conductId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        conductId: null,
        name: '',
        maxScore: null,
        status: null,
        sort: '',
      },
      itemsPerPage: 10,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      currentConductCriteria: undefined,
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'conductCriteria/dataSources',
      totalRows: 'conductCriteria/totalRows',
    }),
    statusOptions() {
      return STATUSES
    },
    paginationOptions() {
      return {
        enabled: true,
      }
    },
    user() {
      return getUser()
    },
    columns() {
      return [
        {
          label: 'Tên tiêu chí',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên tiêu chí',
          },
          sortable: true,
          width: '40%',
          thClass: 'text-center',
        },
        {
          label: 'Điểm tối đa',
          field: 'maxScore',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập điểm tối đa',
          },
          sortable: true,
          width: '20%',
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: STATUSES.map(status => ({ value: status.value, text: status.label })),
            placeholder: 'Chọn trạng thái',
          },
          width: '20%',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Thứ tự',
          field: 'orderNo',
          sortable: true,
          width: '10%',
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: '',
          field: 'action',
          width: '10%',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.CONDUCT_CRITERIA)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.CONDUCT_CRITERIA)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.CONDUCT_CRITERIA)
    },
  },
  methods: {
    ...mapActions({
      getDataSources: 'conductCriteria/getDataSources',
      deleteConductCriteria: 'conductCriteria/deleteConductCriteria',
    }),
    async onShow() {
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.updateParams({ conductId: this.conductId })
      this.isLoading = true
      try {
        await this.getDataSources(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataSourcesFromStore()
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getDataSourcesFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else if (field === 'maxScore') this.updateParams({ sort: `2_${type}` })
        else this.updateParams({ sort: `3_${type}` })
      }
      await this.getDataSourcesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'maxScore')) this.updateParams({ maxScore: columnFilters.maxScore })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getDataSourcesFromStore()
    },
    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getDataSourcesFromStore()
    },
    onEditConductCriteria(conductCriteria) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = conductCriteria
      this.currentConductCriteria = { ...rest }
    },
    onDeleteConductCriteria(conductCriteria) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa tiêu chí <span class="text-danger">${conductCriteria.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteConductCriteria(conductCriteria.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getDataSources(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
  },
}
</script>
